import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Link } from "gatsby"
// import { addSlashButtonLink } from '../../helper'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const MeasurementProsData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'Spend Less Time Implementing',
               content:
                    'Analytics professionals commonly report a 90% reduction in implementation time when using ListenLayer to power measurement. Track forms, chats, ecommerce, and web interactions across multiple analytics & marketing platforms in minutes, not hours.',
          },
          {
               title: 'Centralize Definitions',
               content:
                    'Centralize how you define conversions and KPIs used across your data destinations like Google Ads, GA4, LinkedIn, Facebook, Microsoft Ads, Twitter, and more...',
          },
          {
               title: 'Clean Up Google Tag Manager',
               content:
                    'Implement a clean, simple, and organized measurement strategy that reduces the number of tags, triggers, and custom scripts you need to manage',
          },
          {
               title: 'Data Layer Adoption',
               content:
                    'ListenLayer pushes everything you need into the data layer. Write rules to persist values, and to customize, enhance, and segment your data - no programming required. Your hands will never be tied again!',
          },
          {
               title: '100% First Party, Server Side',
               content:
                    'Implement server side tracking with the flip of a switch. Utilize your own sub domain to achieve a 100% first-party data strategy that boosts your website speed and data accuracy. ',
          },
          {
               title: 'Simplify Ongoing Management',
               content:
                    'Write rules to monitor your analytics implementations and receive notifications if something breaks or changes. Managing your measurement strategy after implementation has never been easier!',
          },
          {
               title: 'Google Analytics Alternative',
               content:
                    'Offer a Google Analytics alternative with more features, prebuilt reports, and insights about actual people and companies on your website. With ListenLayer, you own your data.',
          },
          {
               title: 'Centralize Consent Management',
               content:
                    "Stop using 3rd party tools to manage consent, privacy, and cookie popups from the outside.  ListenLayer's full CMP features allow you to manage your compliance directly at the source.  ",
          },
          {
               title: 'Revenue Attribution',
               content:
                    'Enhance your use of "web analytics" by connecting outside data to understand how marketing acitvities drive down-funnel leads and revenue.',
          },
          {
               title: 'The Future of Cookieless',
               content: "ListenLayer offers a cookieless architecture to future-proof your measurement strategy. You're going to be amazed!",
          },
     ]

     const pageName = 'Measurement Professionals'
     const breadcrumbItems = [
          {
               name: 'Outcomes',
               url: `${'outcomes'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-platform-measurement-pros">
                    <div className="circle-green"></div>
                    <div className="circle-blue"></div>
                    <div className="circle-yellow"></div>
                    <div className="container">
                         <div className="platform-intro-title">
                              <b className="platform-intro-des">Analytics & Data Professionals</b>
                              <h1 className="mt-3">Implement Measurement 10x Faster and Generate Better Insights</h1>
                              <b className="platform-intro-str">No Programming Required</b>
                              <p className="mt-2" style={{ width: '90%' }}>
                                   Your job is to unlock the power of data... to generate insights that drive better marketing results. With
                                   ListenLayer, you'll spend less time "tagging" and more time innovating your measurement strategies.
                              </p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6 mt-3">
                                                  <div className="card-step">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            {item.content && <p className="card-desc">{item.content}</p>}
                                                            {item.otherContentHtml && (
                                                                 <div
                                                                      className="custom-desc"
                                                                      dangerouslySetInnerHTML={{ __html: item.otherContentHtml }}
                                                                 ></div>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
                    <SectionBoxReady
                         title={'Get Started'}
                         content={
                              'See how easy (and powerful) ListenLayer is by opening an account today. Implement yourself or connect with our team for support.'
                         }
                         style={'40px'}
                    />
               </section>
          </Layout>
     )
}

export default MeasurementProsData
